import { Fragment, useMemo, useState, useEffect } from "react";
import { BiNotepad } from "react-icons/bi";
import ItemNote from "./components/ItemNote";
import { convertDataColor } from "../../../common/func/colorConvert";
import style from "./NoteTable.module.scss";
import { useSelector } from "react-redux";

function NoteTable() {
    // Initialize based on screen width
    const [show, setShow] = useState(() => {
        return window.innerWidth > 768; // Hide on mobile by default
    });
    
    const { layers } = useSelector((state) => state.dataMap);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 768) {
                setShow(false); // Auto hide on mobile
            }
            else{
              setShow(true);
            }
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (e.code === "KeyN") {
                setShow((prev) => !prev);
            }
        };
        
        document.addEventListener("keypress", handleKeyPress);
        return () => document.removeEventListener("keypress", handleKeyPress);
    }, []);

    const sortLayers = useMemo(
        () =>
            layers.sort((a, b) => {
                const timea = new Date(a.createdAt);
                const timeb = new Date(b.createdAt);
                return timea - timeb;
            }),
        [layers]
    );

    const dataNote = useMemo(
        () =>
            sortLayers
                .filter((v) => v.activeNote === 1)
                .map((v) => {
                    return {
                        icon: v.icon,
                        arrIcon: v.dataIcon !== "" ? JSON.parse(v.dataIcon) : [],
                        data:
                            v.dataColor === "#ffffff|0_0"
                                ? []
                                : convertDataColor(v.dataColor),
                        titleNote: v.titleNote,
                    };
                }),
        [sortLayers]
    );

    return (
        <>
            {dataNote.length > 0 ? (
                <Fragment>
                    <div
                        className={style.btnShow}
                        onClick={() => {
                            setShow(!show);
                        }}
                    >
                        <BiNotepad />
                    </div>
                    {show ? (
                        <div className={style.container}>
                            <h4 className={style.title}>Chú thích</h4>
                            <div className={style.main}>
                                {dataNote.map((v, i) => (
                                    <ItemNote key={i} data={v} index={i} />
                                ))}
                            </div>
                        </div>
                    ) : null}
                </Fragment>
            ) : null}
        </>
    );
}

export default NoteTable;