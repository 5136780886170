import { memo, useState, useEffect } from 'react';
import clsx from 'clsx';
import ListLayer from '../ListLayer';

function ButtonDisplayLayer() {
    const [toggle, setToggle] = useState(() => {
        return window.innerWidth > 768; // 768px is a common breakpoint for mobile
    });
    
    useEffect(() => {
        const handleKeyPress = e => {
            if (e.code === 'KeyL') {
                setToggle(prev => !prev);
            }
        };
        
        const handleResize = () => {
			if (window.innerWidth <= 768) {
				setToggle(false); // Auto hide on mobile
			}
			else {
				setToggle(true);
			}
        };
        
        document.addEventListener('keypress', handleKeyPress);
        window.addEventListener('resize', handleResize);
        
        return () => {
            document.removeEventListener('keypress', handleKeyPress);
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    return (
        <>
            <div
                className={clsx(['icon_layer'])}
                onClick={() => setToggle(!toggle)}
            >
                <i className="fas fa-layer-group"></i>
            </div>
            <ListLayer active={toggle} />
        </>
    );
}

export default memo(ButtonDisplayLayer);
