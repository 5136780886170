import clsx from "clsx";
import styles from "./styles.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { openDetail, search } from "../../../redux/action/dataMap";
import { useMemo, useRef } from "react";
import { useState } from "react";
import { API } from "../../../constant/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoClose, IoSearch } from "react-icons/io5";

function removeVietnameseTones(str) {
  str = str.replace(/[\u0300-\u036f]/g, "");
  str = str.replace(/đ/g, "d");
  str = str.replace(/Đ/g, "D");
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return str;
}

// Cấu hình cho slider
const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

function DetailVector() {
  const dispatch = useDispatch();
  const { openDetail: detail, keyword, currentData } = useSelector((state) => state.dataMap);
  const ref = useRef(null);
  const [openSearch, setOpenSearch] = useState(false);

  const data = useMemo(() => {
    return detail?.properties || detail;
  }, [detail]);

  const images = useMemo(() => {
    if (detail?.properties?.icon || detail?.icon) {
      return detail?.properties?.icon || detail?.icon;
    } else {
      return [];
    }
  }, [detail?.icon, detail?.properties?.icon]);

  const dataSearch = useMemo(() => {
    if (keyword) {
      // Tìm kiếm trong dữ liệu currentData
      // currentData có dạng [{ key: "value" }, { key: "value" }, ...]

      const searchResults = currentData.filter((item) => {
        // Kiểm tra xem có bất kỳ giá trị nào trong item chứa từ khóa tìm kiếm
        return Object.values(item).some((value) => {
          if (typeof value === "string") {
            // Chuyển đổi cả giá trị và từ khóa thành chữ thường và loại bỏ dấu tiếng Việt
            const normalizedValue = removeVietnameseTones(value.toLowerCase());
            const normalizedKeyword = removeVietnameseTones(keyword.toLowerCase());
            return normalizedValue.includes(normalizedKeyword);
          }
          return false;
        });
      });

      return searchResults;
    }

    return [];
  }, [keyword, currentData]);

  return (
    <div className={clsx(styles.container, { [styles.open]: !!detail })}>
      <div className={clsx(styles.searchContainer, { [styles.open]: openSearch })}>
        <input
          value={keyword}
          className={clsx(styles.search)}
          placeholder="Nhập từ khoá tìm kiếm"
          type="search"
          onChange={(e) => {
            dispatch(search(e.target.value));
          }}
          ref={ref}
        />
        <div className={styles.listSearch}>
          {dataSearch.map((item, index) => {
            return (
              <div
                key={index}
                className={styles.itemSearch}
                onClick={() => {
                  dispatch(openDetail(item));
                  setOpenSearch(false);
                }}
              >
                {Object.keys(item).map((key, index) => {
                  if (key === "icon") {
                    return null;
                  }
                  return (
                    <div key={index} className={styles.itemData}>
                      <span className={styles.title}>{key}: </span>
                      <span className={styles.data}>{item[key]}</span>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.slider}>
        <Slider {...sliderSettings}>
          {images.map((src, index) => (
            <div key={index}>
              <img src={`${API}/upload${src}`} alt="icon" className={styles.image} />
            </div>
          ))}
        </Slider>
      </div>
      <div className={styles.options}>
        <div className={styles.item}>
          <div
            className={styles.icon}
            onClick={() => {
              dispatch(openDetail(null));
              setOpenSearch(false);
            }}
          >
            <IoClose />
          </div>
          <span>Đóng</span>
        </div>
        <div
          className={styles.item}
          onClick={() => {
            setOpenSearch(!openSearch);

            if (!openSearch) {
              ref.current.focus();
            }
          }}
        >
          <div className={styles.icon}>
            <IoSearch />
          </div>
          <span>Tìm kiếm</span>
        </div>
      </div>
      {Object.keys(data || {}).map((key, index) => {
        if (key === "icon") {
          return null;
        }
        return (
          <div key={index} className={styles.itemData}>
            <span className={styles.title}>{key}: </span>
            <span className={styles.data}>{data[key]}</span>
          </div>
        );
      })}
    </div>
  );
}

export default DetailVector;
