import { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import ItemListLayer from '../ItemListLayer';
import style from './ListLayer.module.scss';
import { useCancelToken } from '../../../common/hooks/useCancelToken';
import layerAPI from '../../../api/layer';

function ListLayer({ active = true, title = 'Các lớp bản đồ' }) {
    const { classifys, language, area } = useSelector((state) => state.dataMap);
    const { newCancelToken } = useCancelToken();
    const [filteredClassifys, setFilteredClassifys] = useState([]);
    
    useEffect(() => {
        if (!classifys || classifys.length === 0 || !area?.id || !language?.id) {
            setFilteredClassifys([]);
            return;
        }
        
        const checkClassifys = async () => {
            const validClassifys = [];
            
            for (const classify of classifys) {
                try {
                    // Get layers for this classify with current area and language
                    const [res] = await layerAPI.getList(
                        newCancelToken(),
                        1,
                        100,
                        language.id,
                        area.id,
                        classify.id
                    );
                    
                    if (res && res.length > 0) {
                        validClassifys.push(classify);
                    }
                } catch (error) {
                    console.error(`Error checking layers for classify ${classify.id}:`, error);
                }
            }
            
            setFilteredClassifys(validClassifys);
        };
        
        checkClassifys();
    }, [classifys, area, language, newCancelToken]);
    
    if (filteredClassifys.length === 0) {
        return null;
    }
    
    return (
        <div className={clsx([style.main, { [style.active]: active }])}>
            <h3 className={style.title}>{title}</h3>
            <ul className={style.list}>
                {filteredClassifys.map((item) => (
                    <ItemListLayer
                        key={item.id}
                        nameItem={item.nameClassify}
                        defaultActive={item.show}
                        classifyId={item.id}
                    />
                ))}
            </ul>
        </div>
    );
}

export default memo(ListLayer);
